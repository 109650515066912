import { CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import TestButton from "./components/TestButton";
import TestCalendar from "./components/TestCalendar";
import TestRte from "./components/TestRte";

const Test = (
    <CustomRoutes>
        <Route path="/test/button" element={<TestButton />} />
        <Route path="/test/calendar" element={<TestCalendar />} />
        <Route path="/test/rte" element={<TestRte />} />
    </CustomRoutes>
);

export default Test;
