import React, { Fragment, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Tab,
    Tabs,
    Theme,
    useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate, useParams } from "react-router-dom";
import DocumentPreviewer from "./component/DocumentPreviewer";
import DownloadButton from "./component/DownloadButton";
import DocumentBreadcrumbs from "./component/DocumentBreadcrumbs";
import DocumentPreviewDetailsTab from "./component/documentPreview/DocumentPreviewDetailsTab";
import DocumentPreviewNotesTab from "./component/documentPreview/DocumentPreviewNotesTab";
import useGetTags from "./data/useGetTags";
import useGetFile from "./data/useGetFile";
import useGetFileDetails from "./data/useGetFileDetails";
import useGetPreviewUrl from "./data/useGetPreviewUrl";
import useGetFolderDetails from "./data/useGetFolderDetails";
import { Title } from "react-admin";

export enum PREVIEW_TAB {
    DETAILS,
    NOTES,
    PERMISSIONS,
}

const DocumentPreview = () => {

    const { fileId } = useParams();

    const navigate = useNavigate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const { data: file, isLoading: isFileLoading } = useGetFileDetails(fileId);
    const { data: tags, isLoading: isTagsLoading } = useGetTags();
    const { data: currentFolder, isLoading } = useGetFolderDetails(file ? file.folderId ? `${file.folderId}` : undefined : undefined);

    const [ tab, setTab ] = useState(PREVIEW_TAB.DETAILS);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    if (isFileLoading) {
        return "Loading...";
    }

    if (! file) {
        return "Unable to retrieve file?";
    }

    return (
        <Fragment>
            <Title title="Document Viewer" />

            <DocumentBreadcrumbs folderDetails={currentFolder} file={file} />


            <Card sx={{ my: 1 }}>
                <CardHeader
                    title={file.filename}
                    action={
                        <Stack direction="row">
                            <DownloadButton file={file} />
                        </Stack>
                    }
                />
                <Divider />
                <CardContent sx={{ p: 0, pt: "8px", pb: 0, minHeight: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={4} sx={{ borderRight: "1px solid #DDD" }}>
                            <Box sx={{ p: 1, height: "calc(100vh - 250px)", overflowY: "auto" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                    <Tabs
                                        scrollButtons={true}
                                        value={tab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        sx={{
                                            "& button": {
                                                textTransform: "none",
                                                minWidth: "40px",
                                            },
                                        }}
                                    >
                                        <Tab label="Details" value={PREVIEW_TAB.DETAILS} />
                                        <Tab label="Notes" value={PREVIEW_TAB.NOTES}/>
                                        <Tab label="Permissions" value={PREVIEW_TAB.PERMISSIONS} />
                                    </Tabs>
                                </Box>
                                {tab === PREVIEW_TAB.DETAILS && (<DocumentPreviewDetailsTab file={file} />)}
                                {tab === PREVIEW_TAB.NOTES && (<DocumentPreviewNotesTab />)}
                            </Box>
                        </Grid>
                        <Grid xs={12} md={8}>
                            <Box
                                sx={{
                                    p: 1,
                                    height: "calc(100vh - 250px)"
                                }}
                            >
                                <DocumentPreviewer serverPath={file.serverFile.relativeLocation} />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default DocumentPreview;
