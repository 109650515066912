import React, { Fragment, useState } from "react";
import { Collapse, ListItemIcon, ListItemText, MenuItem, MenuList, Theme, useMediaQuery } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRedirect, useSidebarState } from "react-admin";
import { matchPath } from "react-router";
import { useLocation, useMatch } from "react-router-dom";
import { useBasename } from "ra-core";
import {
    getSelectedProjectFeatures,
    getUserFeatures
} from "../../auth/AuthUtils";

const getSublistLinks = (items: ApplicationMenuItem[]) => {
    return items.reduce((prev: string[], item) => {
        if (item.link) {
            prev.push(item.link);
        }
        if (item.items && item.items.length > 0) {
            prev.push(...getSublistLinks(item.items));
        }
        return prev;
    }, []);
}

const AppMenuItem: React.FC<{
    item: ApplicationMenuItem,
    isChild?: boolean,
}> = ({ item, isChild = false }) => {

    const { name,
        link,
        Icon, items,
        disabled,
        permissionRequired = false,
        superAdminRequired = false,
        selectable = true,
        specialPathRegex,
    } = item;
    const isExpandable = !! (items && items.length > 0);

    const { pathname } = useLocation();
    const basename = useBasename();
    const sublistLinks: string[] = isExpandable ? getSublistLinks(items) : [];

    const isMatch = sublistLinks.reduce((prev, sublistLink) => {
        return prev ? prev : !! (matchPath({
            path: sublistLink,
            end: sublistLink === `${basename}/`,
        }, pathname) && (!!specialPathRegex && specialPathRegex?.match(sublistLink)));
    }, false);

    const redirect = useRedirect();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [ sideBarOpen, setSideBarOpen ] = useSidebarState();

    const to = link || "xxxxxxx";
    const match = useMatch({ path: to, end: link === `${basename}/` });
    const [ open, setOpen ] = useState((!! match) || isMatch);

    const handleClick = () => {
        if (isExpandable) {
            setOpen(! open);
        } else {
            if (link) {
                redirect(link);
                // Responsive Setting
                // See https://marmelab.com/react-admin/Features.html#responsive
                if (isSmall) {
                    setSideBarOpen(false);
                }
            }
        }
    }

    const MenuItemRoot = () => (
        <MenuItem onClick={handleClick}
                  selected={!! match && selectable}
                  disabled={disabled}
                  sx={{
                      "&.Mui-selected": {
                          boxShadow: "inset 5px 0 0 #ab2230",
                      },
                      ":hover": {
                          boxShadow: "inset 5px 0 0 #337ab7",
                          color: "#337ab7",
                      },
                  }}
        >
            {!! Icon && (
                <ListItemIcon sx={{
                    color: "inherit",
                }}>
                    <Icon />
                </ListItemIcon>
            )}
            <ListItemText inset={! Icon} sx={{
                // "& .MuiTypography-root": {
                //     fontWeight: "bold",
                // },
            }}>
                {name}
            </ListItemText>
            {isExpandable && ! open && <ExpandMoreIcon />}
            {isExpandable && open && <ExpandLessIcon />}
        </MenuItem>
    );

    const MenuItemChildren = () => isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <MenuList component="div" disablePadding>
                {items.map((item, index) => (
                    <AppMenuItem item={item} key={index} isChild />
                ))}
            </MenuList>
        </Collapse>
    ) : null;

    return (
        <>
            {
                ((!permissionRequired && !superAdminRequired)
                || (permissionRequired && getSelectedProjectFeatures().includes(permissionRequired))
                || (superAdminRequired && getUserFeatures().includes("SUPER_ADMIN")))
                && (
                <Fragment>
                    <MenuItemRoot />
                    <MenuItemChildren />
                </Fragment>
            )}
        </>
    );
}

export default AppMenuItem;
