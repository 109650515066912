import axios from "axios";
import config from "../../../config.json";
import { getAuth } from "../../../auth/AuthUtils";

const uploadFile = async (projectUuid: string, userUuid: string, filename: string, file: File, forceUpload: boolean = false) => {
    const formData: FormData = new FormData();
    formData.append("file", file);
    return axios.put(`${config.DOCUMENT_API_BASE_URL}/project/${projectUuid}/user/${userUuid}/Microsoft/upload/folder/${projectUuid}/${filename}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getAuth()}`,
            },
            params: {
                forceUpload,
            },
        }).then(result => result.data);
};

export default uploadFile;
