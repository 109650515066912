import React from 'react';
import {
    FilterForm,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
    useListContext,
    useTranslate,
} from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    InputAdornment,
    Popover,
    Stack,
    Typography,
    List as MuiList,
    ListItem as MuiListItem,
    ListItemButton as MuiListItemButton, useMediaQuery, Theme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CreateIconButton from "../../component/CreateIconButton";
import lodashGet from "lodash/get";
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import VoucherMobileGrid from "./VoucherMobileGrid";
import VoucherDesktopGrid from "./VoucherDesktopGrid";

const VoucherList = () => {

    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const voucherListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Index',
            path: "/voucher",
        },
        {
            label: 'List',
            isCurrent: true,
        },
    ];

    const listFilters = [
        <TextInput label="common.table.search"
                   source="q"
                   name="q"
                   alwaysOn
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <SearchIcon color="disabled" />
                           </InputAdornment>
                       ),
                   }} />,
        <TextInput label="resources.voucher.common.recordId"
                   source="recordId"
                   name="recordId"
        />,
        <TextInput label="resources.voucher.common.name"
                   source="name"
                   name="name"
        />,
        <SelectArrayInput source="status"
                          label="resources.voucher.common.status"
                          name="status"
                          choices={[
                              { id: 'A', name: 'common.status.voucher.active' },
                              { id: 'C', name: 'common.status.voucher.consumed' },
                              { id: 'X', name: 'common.status.voucher.deleted' },
                          ]}
                          variant="outlined"
                          sx={{
                              "& .RaSelectArrayInput-chip": {
                                  my: 0,
                              },
                          }} />,
        <TextInput label="resources.voucher.common.issuer"
                   source="issuer"
                   name="issuer"
        />,
        <ReferenceArrayInput
            name="retailerId"
            reference="retailer"
            source="retailerId"
            label="Retailers"
        >
            <SelectArrayInput source="name"
                              variant="outlined"
            />
        </ReferenceArrayInput>,

    ];

    const defaultFilters = {}

    const ListFilter = () => {

        const translate = useTranslate();
        const { setFilters, filterValues, displayedFilters, showFilter } = useListContext();

        const hiddenFilters = listFilters.filter(
            (filterElement: React.JSX.Element) =>
                ! filterElement.props.alwaysOn &&
                ! displayedFilters[filterElement.props.source] &&
                typeof lodashGet(filterValues, filterElement.props.source) ===
                'undefined'
        );

        return (
            <Stack direction="row" justifyContent="space-between">
                <FilterForm filters={listFilters} />
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <React.Fragment key="addFilterPopover">
                            <Button {...bindTrigger(popupState)}>
                                {translate("common.table.filter.add")}
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography sx={{ m: 2 }}>Select Filters</Typography>
                                <Divider />
                                <MuiList>
                                    {hiddenFilters.map((hiddenFilter, index) => {
                                        return (
                                            <MuiListItem key={`addFilter-item-${index}`}>
                                                <MuiListItemButton
                                                    onClick={() => {
                                                        showFilter(hiddenFilter.props.source, undefined);
                                                    }}
                                                >
                                                    {translate(hiddenFilter.props.label)}
                                                </MuiListItemButton>
                                            </MuiListItem>
                                        )
                                    })}
                                </MuiList>
                            </Popover>
                        </React.Fragment>
                    )}
                </PopupState>
                <Button onClick={() => setFilters(defaultFilters, {})}>
                    {translate("common.table.filter.reset")}
                </Button>
            </Stack>
        );
    }

    return (
        <List actions={false}
              exporter={false}
              sort={{ field: 'recordId', order: 'ASC' }}
              perPage={25}
              component="div"
              title="Voucher Management"
              filterDefaultValues={defaultFilters}
              empty={false}
        >
            <Box>
                <CustomBreadcrumbs title={translate('resources.voucher.page.list.title')}
                                   items={voucherListBreadcrumbItems}
                />
                <Card>
                    <CardHeader
                        title="Vouchers"
                        action={<CreateIconButton />}
                    />
                    <Divider />
                    <CardContent sx={{ padding: "2px 16px" }}>
                        <ListFilter />
                    </CardContent>
                    <Divider />
                    <CardContent>
                        { isXSmall ? <VoucherMobileGrid /> : <VoucherDesktopGrid /> }
                    </CardContent>
                </Card>
            </Box>
        </List>
    )
}

export default VoucherList;