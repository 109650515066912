import React, { Fragment, useState } from "react";
import CustomIconButton from "../../../../component/common/CustomIconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateFolder } from "../../data/useDocumentData"
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useGetFolderDetails from "../../data/useGetFolderDetails";

const FolderCreateButton: React.FC = () => {

    type FolderCreateForm = {
        name: string;
        description: string;
    };

    const { folderId } = useParams();
    const { refetch: folderDetailsRefetch } = useGetFolderDetails(folderId);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const { mutation: createFolderMutation } = useCreateFolder();

    const formProps = useForm<FolderCreateForm>({
        reValidateMode: "onSubmit",
        defaultValues: {
            name: "",
            description: "",
        },
    });

    const dialogOpenHandler = () => {
        setDialogOpen(true);
    };

    const dialogCloseHandler = () => {
        setDialogOpen(false);
        formProps.reset();
    };

    const onSubmitHandler: SubmitHandler<FolderCreateForm> = (data, event) => {
        event?.preventDefault();
        createFolderMutation.mutateAsync({
            name: data.name,
            description: data.description,
            parentFolderId: folderId ? +folderId : undefined,
        }).then(r => {
            dialogCloseHandler();
            folderDetailsRefetch().then();
        }).catch(error => {
            const errorDetails = error.response.data;

            if (errorDetails.message === "Folder name already used") {
                formProps.setError("name", {
                    type: "custom",
                    message: "Folder name already used",
                }, {
                    shouldFocus: true,
                });
            }
            console.error(error);
        })
    };

    return (
        <Fragment>
            <CustomIconButton size="small" color="softPrimary" onClick={dialogOpenHandler}>
                <AddOutlinedIcon />
            </CustomIconButton>

            <Dialog
                open={dialogOpen}
                onClose={dialogCloseHandler}
                PaperProps={{
                    component: "form",
                    noValidate: true,
                    onSubmit: formProps.handleSubmit(onSubmitHandler),
                }}

            >
                <DialogTitle>
                    Create Folder
                    <IconButton
                        aria-label="close"
                        onClick={dialogCloseHandler}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>


                    <Controller
                        render={({ field, fieldState, formState }) => {
                            console.log(fieldState);
                            return (
                                <TextField
                                    value={field.value}
                                    onChange={field.onChange}
                                    error={!! fieldState.error}
                                    autoFocus
                                    type="text"
                                    label="Name"
                                    fullWidth
                                    helperText={fieldState.error?.message}
                                    required
                                />
                            );
                        }}
                        rules={{ required: true }}
                        name="name"
                        control={formProps.control}
                    />

                    <Controller
                        render={({ field, fieldState }) =>
                            <TextField
                                value={field.value}
                                onChange={field.onChange}
                                error={!! fieldState.error}
                                type="text"
                                label="Description"
                                fullWidth
                                helperText={fieldState.error?.message}
                            />}
                        name="description"
                        control={formProps.control}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogCloseHandler}>Cancel</Button>
                    <Button type="submit" disabled={createFolderMutation.isPending}>Create</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default FolderCreateButton;
