import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import getDownloadUrl from "../data/getDownloadUrl";
import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import axios from "axios";
import useGetFileDetails from "../data/useGetFileDetails";
import { useParams } from "react-router-dom";

const DownloadButton: React.FC<{ file: FileV2 }> = ({ file }) => {

    const [ isDownloading, setIsDownloading ] = useState(false);

    useEffect(() => {
        setIsDownloading(false);
    }, []);

    const download = () => {
        setIsDownloading(true);
        const projectUuid = getProjectUuid() || "";
        const userUuid = getUserUuid() || "";
        getDownloadUrl(projectUuid, userUuid, file.serverFile.relativeLocation)
            .then(result => result.data)
            .then(result => {
                axios
                    .get(result.downloadUrl, {
                        responseType: "blob"
                    })
                    .then(result => {
                        const element = window.document.createElement("a");
                        element.href = window.URL.createObjectURL(
                            new Blob([ result.data ]),
                        );
                        element.download = file.filename;
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        setIsDownloading(false);
                    });
            });
    };

    return (
        <Button onClick={() => download()} disabled={isDownloading}>Download</Button>
    );
};

export default DownloadButton;