import React, { ChangeEvent, Fragment, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CustomIconButton from "../../../../component/common/CustomIconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUploadFile } from "../../data/useDocumentData";

const FileUploadButton: React.FC = () => {

    type FileUploadForm = {
        file: FileList
    };

    const [ file, setFile ] = useState<File>();
    const [ error, setError ] = useState<String | null>(null);
    const [ isDialogOpen, setDialogOpen ] = useState(false);
    const { mutation: uploadFileMutation } = useUploadFile();

    const formProps = useForm<FileUploadForm>({
        reValidateMode: "onSubmit",
        defaultValues: {
            file: undefined,
        },
    });

    const dialogOpenHandler = () => {
        setDialogOpen(true);
    };

    const dialogCloseHandler = () => {
        setDialogOpen(false);
        formProps.reset();
    };

    const onSubmitHandler = () => {
        console.log(file);
        if (file) {
            uploadFileMutation.mutateAsync({
                file: file,
            }).then(result => {
                dialogCloseHandler();
            });
        }
    };

    return (
        <Fragment>
            <CustomIconButton size="small" color="softPrimary" onClick={dialogOpenHandler}>
                <AddOutlinedIcon />
            </CustomIconButton>

            <Dialog
                open={isDialogOpen}
                onClose={dialogCloseHandler}
                PaperProps={{
                    component: "form",
                    noValidate: true,
                    onSubmit: (e: any) => {
                        e.preventDefault();
                        onSubmitHandler();
                    },
                }}

            >
                <DialogTitle>
                    Upload files
                    <IconButton
                        aria-label="close"
                        onClick={dialogCloseHandler}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>


                    <TextField
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (event.target.files) {
                                setFile(event.target.files[0]);
                            } else {
                                setFile(undefined);
                            }
                        }}
                        error={!! error}
                        autoFocus
                        type="file"
                        label="File"
                        fullWidth
                        helperText={error}
                        required
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogCloseHandler}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

};

export default FileUploadButton;
