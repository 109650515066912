import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Title } from "react-admin";
import { Fragment, useState } from "react";
import { EventInput, EventSourceInput } from "@fullcalendar/core";


const TestCalendar = () => {

    const [ selectedEvent, setSelectedEvent ] = useState<any | undefined>(undefined);
    const [ events, setEvents ] = useState<EventSourceInput>(
        [
            {
                id: "A1",
                title: "Meeting 123",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                extendedProps: {
                    eventEntity: {
                        recordId: 1,
                        name: "Meeting 123",
                        description: "AAAAAA",
                        createdBy: "Peter",
                        createdDate: moment().toISOString(),
                        updatedBy: "Peter",
                        updatedDate: moment().toISOString(),
                    },
                },
            },
            {
                id: "A2",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                extendedProps: {
                    eventEntity: {
                        recordId: 2,
                        description: "AAAAAA",
                        createdBy: "Peter",
                        createdDate: moment().toISOString(),
                        updatedBy: "Peter",
                        updatedDate: moment().toISOString(),
                    },
                },
            },
            {
                id: "A3",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                extendedProps: {
                    eventEntity: {
                        recordId: 3,
                        createdBy: "Peter",
                        createdDate: moment().toISOString(),
                        updatedBy: "Peter",
                        updatedDate: moment().toISOString(),
                    },
                },
            },
            {
                id: "A4",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                extendedProps: {
                    eventEntity: {
                        recordId: 4,
                        createdBy: "Peter",
                        createdDate: moment().toISOString(),
                        updatedBy: "Peter",
                        updatedDate: moment().toISOString(),
                    },
                },
            },
            {
                id: "A5",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
            },
            {
                id: "A6",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
            },
            {
                id: "A7",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
            },
            {
                id: "A8",
                title: "Meeting",
                start: moment().startOf("hour").toDate(),
                end: moment().endOf("hour").toDate(),
            },
            {
                id: "A9",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").add(2, "day").toDate(),
            },
        ],
    );

    const SelectedEventDialog = (selectedEvent: any | undefined) => {

        if (! selectedEvent) return <Fragment />;

        return (
            <Fragment>
                <DialogTitle>{selectedEvent.name}</DialogTitle>
                <DialogContent>
                    <Box>
                        {JSON.stringify(selectedEvent)}
                    </Box>
                </DialogContent>
            </Fragment>
        );
    };

    return (
        <Box sx={{
            "& .fc": {
                "& .fc-popover": {
                    backgroundColor: "#000",
                },
                "& .fc-list-event:hover td": {
                    color: "#000",
                },
            },
        }}>
            <Title title="Calendar - Test" />
            <FullCalendar
                plugins={[ dayGridPlugin, listPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                eventBackgroundColor="#fd54ee"
                events={events}
                headerToolbar={{
                    left: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    center: "title",
                    right: "prevYear,prev,next,nextYear",
                }}
                editable={true}
                height={"80vh"}
                dayMaxEventRows={5}
                eventClick={(e) => {
                    console.log(e);
                    setSelectedEvent(e.event.extendedProps);
                }}
                eventDragStart={(e) => {
                    console.log(e);
                }}
                eventDragStop={(e) => {
                    console.log(e);
                }}
                eventChange={(e) => {
                    console.log("Event Change: ", e);
                }}
                dateClick={(e) => {
                    console.log(e);
                }}
                selectable
                timeZone="Europe/London"
                locale="en-GB"
                eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    meridiem: false,
                    hour12: false,
                }}
            />

            <Dialog open={!! selectedEvent} onClose={(event, reason) => {
                setSelectedEvent(undefined);
            }}>
                <SelectedEventDialog selectedEvent={selectedEvent} />
            </Dialog>

        </Box>
    );
};

export default TestCalendar;
