import axios from "axios";
import config from "../../../config.json";
import { getHeaders } from "../../../helper/RestUtils";
import { CreateFolderRequest } from "./types";

const createFolder = async (projectUuid: string, userUuid: string, request: CreateFolderRequest) => {
    return axios.post(`${config.BASE_URL}/project/${projectUuid}/user/${userUuid}/document/folder/create`, request, {
        headers: getHeaders(),
    }).then(result => result.data);
};

export default createFolder;
